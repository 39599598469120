const moduleOptions = `{"logo":{"src":"/logo.png","width":191,"height":58},"imageSizes":{"productCard":{"width":233,"height":233}}}`;
var plugin_default = (context, inject) => {
  var _a;
  const options = JSON.parse(moduleOptions);
  let wm = (_a = context.$wm) != null ? _a : {};
  wm.theme = options;
  inject("wm", wm);
};
export {
  plugin_default as default
};

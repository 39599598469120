const moduleOptions = `{"generateRoutes":true,"baseRoute":"checkout","accountPath":"konto","thankYouPath":"takk","orderPath":"bestilling","cartPath":"cart","checkoutRoute":null,"enableGuestCheckout":false,"guestCheckoutRoute":"","registrationEnabled":true,"lockToCompanyRegistration":false,"setDefaultShippingMethod":false,"svea":{"enabled":false,"enabledForLoggedIn":true,"route":"sveaCheckout","paymentMethod":"sveacheckout"},"customOrderFields":{"customer_ref":{"required":false,"show":true},"customer_order_no":{"required":false,"show":true}},"useCartPage":false}`;
var plugin_default = (context, inject) => {
  const options = JSON.parse(moduleOptions);
  let wm = context.$wm || {};
  wm.checkout = options;
  inject("wm", wm);
};
export {
  plugin_default as default
};

import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _713491da = () => interopDefault(import('../_templates/pages/blog/Blog.vue' /* webpackChunkName: "" */))
const _0263b5da = () => interopDefault(import('../_templates/pages/checkout/Checkout.vue' /* webpackChunkName: "" */))
const _03fe31b2 = () => interopDefault(import('../_templates/pages/checkout/Checkout/Order.vue' /* webpackChunkName: "" */))
const _0e9d2a11 = () => interopDefault(import('../_templates/pages/checkout/Checkout/Account.vue' /* webpackChunkName: "" */))
const _586882a1 = () => interopDefault(import('../_templates/pages/checkout/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _6f98d524 = () => interopDefault(import('../_templates/pages/customer/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _5adcd2dc = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _33781058 = () => interopDefault(import('../_templates/pages/customer/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _2255e51c = () => interopDefault(import('../_templates/pages/customer/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _31236ca8 = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _034f3932 = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _717c8457 = () => interopDefault(import('../_templates/pages/customer/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _07c6bcfe = () => interopDefault(import('../_templates/pages/faq/Faq.vue' /* webpackChunkName: "" */))
const _d16d71da = () => interopDefault(import('../_templates/pages/search/Search.vue' /* webpackChunkName: "" */))
const _d77a0ed2 = () => interopDefault(import('../_templates/pages/customer/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _104aa35a = () => interopDefault(import('../_templates/pages/blog/BlogPost.vue' /* webpackChunkName: "" */))
const _f8b34674 = () => interopDefault(import('../_templates/pages/attribute-collection/AttributeCollection.vue' /* webpackChunkName: "" */))
const _67a8990e = () => interopDefault(import('../_templates/pages/attribute-collection/AttributeCollectionItem.vue' /* webpackChunkName: "" */))
const _752bb531 = () => interopDefault(import('../_templates/pages/theme/Home.vue' /* webpackChunkName: "" */))
const _753e6a3e = () => interopDefault(import('../_templates/pages/theme/Page.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aktuelt.html",
    component: _713491da,
    name: "blog___no"
  }, {
    path: "/checkout",
    component: _0263b5da,
    name: "checkout___no",
    children: [{
      path: "bestilling",
      component: _03fe31b2,
      name: "checkout-order___no"
    }, {
      path: "konto",
      component: _0e9d2a11,
      name: "checkout-account___no"
    }, {
      path: "takk",
      component: _586882a1,
      name: "checkout-thank-you___no"
    }]
  }, {
    path: "/customer",
    component: _6f98d524,
    meta: {"titleLabel":"Account"},
    name: "customer___no",
    children: [{
      path: "addresses-details",
      component: _5adcd2dc,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___no"
    }, {
      path: "my-profile",
      component: _33781058,
      meta: {"titleLabel":"My Account"},
      name: "customer-my-profile___no"
    }, {
      path: "order-history",
      component: _2255e51c,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___no"
    }, {
      path: "addresses-details/create",
      component: _31236ca8,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___no"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _034f3932,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___no"
    }, {
      path: "order-history/:orderId",
      component: _717c8457,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___no"
    }]
  }, {
    path: "/faq.html",
    component: _07c6bcfe,
    name: "faq___no"
  }, {
    path: "/search",
    component: _d16d71da,
    name: "search___no"
  }, {
    path: "/customer/reset-password",
    component: _d77a0ed2,
    alias: "/customer/account/createPassword",
    name: "reset-password___no"
  }, {
    path: "/aktuelt/:slug+.html",
    component: _104aa35a,
    name: "blogPost___no"
  }, {
    path: "/collection/:slug([^/.]+).html",
    component: _f8b34674,
    name: "attributeCollection___no"
  }, {
    path: "/collection/:collection_slug/:slug+.html",
    component: _67a8990e,
    name: "attributeCollectionItem___no"
  }, {
    path: "/",
    component: _752bb531,
    name: "home___no"
  }, {
    path: "/:slug+",
    component: _753e6a3e,
    name: "page___no"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
